import React from "react";
import {Navbar} from "../components/navbar.component";
import {Footer} from "../components/footer.component";
import {graphql} from "gatsby";
import {CustomLink} from "../components/custom-link.component";
import {GatsbyImage} from "gatsby-plugin-image";
import moment from "moment";
import {getLangPrefix} from "../utils/langs";

/**
 * @param {PrismicMenu} mainMenu
 * @param {PrismicMenu} footerMenu
 * @param {PrismicSocialLinks} prismicSocialLinks
 * @param {PrismicBlogCategoryConnection} allPrismicBlogCategory
 * @param {PrismicBlogPostConnection} allPrismicBlogPost
 *
 * @param sendOrderForm
 * @param contacts
 * @param lang Category language
 * @return {JSX.Element}
 * @constructor
 */
const BlogCategory = ({
                          data: {
                              mainMenu,
                              footerMenu,
                              prismicSocialLinks,
                              allPrismicBlogCategory,
                              allPrismicBlogPost,
                              sendOrderForm,
                              contacts
                          },
                          pageContext: {
                              lang
                          }
                      }) => {
    return (
        <>
            <Navbar menu={mainMenu}
                    social={prismicSocialLinks}
                    lang={lang}
                    sendOrderForm={sendOrderForm}
                    contacts={contacts}/>
            <section className="section-banner">
                <div className={'background-gray'}>
                    <div className="container">
                        <div className="main-heading">
                            <h1>Блог</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="category">
                <div className="container px-3">
                    <ul className="d-block d-md-flex mb-0">
                        {allPrismicBlogCategory.edges.map(item => {
                            const {uid, data: {name}} = item.node;
                            return (
                                <li>
                                    <div className="me-0 me-md-3">
                                        <CustomLink link={`${getLangPrefix(lang)}/blog/${uid}`}
                                                    className="parallelogram py-2 gradient-blue active">
                                            <div className="heading text-light">
                                                {name}
                                            </div>
                                        </CustomLink>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <section className="container pt-0 padding-wrapper">
                <div className="row">
                    {allPrismicBlogPost.edges.map(item => {
                        const {uid, data: {category, image, title}, first_publication_date} = item.node;
                        return (
                            <div className="col-12 col-lg-4 mb-3">
                                <CustomLink link={`${getLangPrefix(lang)}/blog/${category.uid}/${uid}`}>
                                    <div className="card">
                                        <GatsbyImage image={image.gatsbyImageData} className="card-img-top"/>
                                        <div className="card-body bg-light text-center">
                                            <small className="card-category">{category.document.data.name}</small>
                                            <h5 className="card-title text-primary mb-3">{title.text}</h5>
                                            <small className="card-date">{moment(first_publication_date).format('DD.MM.YYYY')}</small>
                                        </div>
                                    </div>
                                </CustomLink>
                            </div>
                        )
                    })}
                </div>
            </section>
            <Footer menu={footerMenu} socials={prismicSocialLinks} contacts={contacts}/>
        </>
    )
}

export default BlogCategory;

export const query = graphql`
    query BlogCategoryQuery($id: String!, $lang: String!) {
        allPrismicBlogCategory(filter: {lang: {eq: $lang}}) {
            edges {
                node {
                    ...blogCategory
                }
            }
        }
        allPrismicBlogPost(filter: {data: {category: {uid: {eq: $id}}}, lang: {eq: $lang}}) {
            edges {
                node {
                    ...blogPost
                }
            }
        }
        mainMenu: prismicMenu(uid: {eq: "main-menu"}, lang: { eq: $lang }) {
            ...menu
        }
        footerMenu: prismicMenu(uid: {eq: "footer-menu"}, lang: { eq: $lang }) {
            ...menu
        }
        sendOrderForm: prismicSendOrderForm(lang: {eq: $lang}){
            ...sendOrderForm
        }
        contacts: prismicContacts{
            ...contacts
        }
        prismicSocialLinks(type: {eq: "social_links"}) {
            ...socials
        }
    }
`
